const validateHexCode = (hexCode) => {
  const pattern = /^[0-9A-F]{8}$/i;
  return pattern.test(hexCode);
};

const validateEmail = (email) => {
  // const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const pattern =
  /(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/;

  return pattern.test(email);
};

const validateSuiAddress = (publicAddress) => {

  const pattern = /^0x[0-9a-f]{64}$/i;

  return pattern.test(publicAddress);
};

export {validateHexCode, validateEmail, validateSuiAddress};
