import {React, useState} from "react"
import ctas from "./CtaPage.module.css"
import axios from 'axios';

import { motion } from "framer-motion"

import {validateEmail, validateHexCode, validateSuiAddress} from "../../utils/validators"


const CallToActionPageComponent = () => {
  const [email, setEmail] = useState('');
  const [publicAddress, setPublicAddress] = useState('');
  const [hexCode, setHexCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    if (!publicAddress || !validateSuiAddress(publicAddress)) {
      setErrorMessage('Please enter a valid public address.');
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    if (!hexCode || !validateHexCode(hexCode)) {
      setErrorMessage('Please enter a valid hex code.');
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    //Clear previous error messages
    setErrorMessage("");

    try {
      const userData = {
        publicAddress: publicAddress,
        email: email,
        hexCode: hexCode.toUpperCase(),
      };

      //api
      const response = await axios.post('https://europe-west2-act-info-c5ec5.cloudfunctions.net/api/whitelist', userData);

      if (response.status === 200) {

        setEmail('');
        setPublicAddress('');
        setHexCode('');
        setErrorMessage('');
        setSuccessMessage('Whitelisted Successfully!'); // Set success message
        setTimeout(() => setSuccessMessage(''), 5000); // Clear success message after 5 seconds

      }

    } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 400) {
        console.log("400 error")
        setErrorMessage(error.response.data.error);
        setTimeout(() => setErrorMessage(''), 5000);

      } else {
        setErrorMessage('Something went wrong... Try again later.');
        setTimeout(() => setErrorMessage(''), 5000);
      }
    }

  };

  return (
    <motion.div id={ctas.ctaPageContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >

      <form id={ctas.contentContainer} onSubmit={handleSubmit}>
        <div className={ctas.gjsGridRow} id={ctas.formTitleContainer}>

          <div className={ctas.gjsGridColumn} id={ctas.textContainer}>
            <div id={ctas.formTitleText}>SUBMIT DETAILS FOR</div>
            <div id={ctas.formTitleText}>WHITELIST</div>

          </div>

        </div>

        <div className={ctas.gjsGridRow} id={ctas.formContainer}>

          <div className={ctas.gjsGridColumn} id={ctas.formItemsWrapper}>
            <div className={ctas.gjsGridRow} id={ctas.formItemsContainer}>

              {errorMessage && (
                <motion.div
                  id={ctas.errorText}
                  initial={{ opacity: 0, y: "-2vw" }}
                  animate={{ opacity: 1, y: "-7.5vw", transition: { duration: 0.5 } }}
                  exit={{ opacity: 0, y: "-7.5vw", transition: { duration: 0.5 } }}
                >
                  {errorMessage}
                </motion.div>
              )}
              {successMessage && (
                <motion.div
                  id={ctas.successMessage}
                  initial={{ opacity: 0, y: "-2vw" }}
                  animate={{ opacity: 1, y: "-7.5vw", transition: { duration: 0.5 } }}
                  exit={{ opacity: 0, y: "-7.5vw", transition: { duration: 0.5 } }}
                >
                  {successMessage}
                </motion.div>
              )}
              <motion.div id={ctas.inputFields}
                initial={{ opacity: 1, y: 0 }}
                animate={errorMessage || successMessage ? { opacity: 1, y: "4vh" } : { opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <input
                  type="text"
                  id={ctas.addressInput}
                  placeholder="Enter Your Public Address."
                  value={publicAddress}
                  onChange={(e) => setPublicAddress(e.target.value)}
                />

                <input
                  type="email"
                  id={ctas.emailInput}
                  placeholder="Enter Your Email Address."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  id={ctas.hexCodeInput}
                  placeholder="Enter Your Hex Code."
                  value={hexCode}
                  onChange={(e) => setHexCode(e.target.value)}
                />
              </motion.div>

            </div>
          </div>
        </div>

        <div className={ctas.gjsGridRow} id={ctas.submitContainer}>
          <div className={ctas.gjsGridColumn} id={ctas.submitRowColumn}>
            <button id={ctas.submitButton} alt="submit" type="submit"></button>
          </div>
        </div>
      </form>

    </motion.div>
  );
};

export default CallToActionPageComponent;
