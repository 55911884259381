import discordLogo from "../../assets/SVG/discordLogo.svg";
import xLogo from "../../assets/SVG/xLogo.svg";
import bracketL from "../../assets/SVG/bracketL.svgz"
import actLogo from "../../assets/SVG/ACT.svgz"
import actAcronym from "../../assets/SVG/acronym.svgz"

import cs from "./ConnectPage.module.css"

import { Link } from 'react-router-dom';
import { motion, useAnimate } from "framer-motion"

const ConnectPageComponent = () => {
  const [scope, animate] = useAnimate();

  const expandBracket = (() => {
    animate(`.${cs.leftBracket}`, { transform: 'translateX(-2vw)' })
    animate(`.${cs.rightBracket}`, { transform: 'translateX(2vw) scaleX(-1)' })
    animate(`.${cs.connectButton}`, { filter: 'brightness(70%)' })
  });

  const shrinkBracket = (() => {
    animate(`.${cs.leftBracket}`, { transform: 'translateX(0vw)' })
    animate(`.${cs.rightBracket}`, { transform: 'translateX(0vw) scaleX(-1)' })
    animate(`.${cs.connectButton}`, { filter: 'brightness(100%)' })

  });


  return (
    <motion.div
      ref={scope}
      id={cs.connectPageContainer}
      exit={{ opacity: 0, transition: { duration: 1 } }} 
    >

      <div className={cs.gjsGridRow} id={cs.titleContainer}>
        <div className={cs.gjsGridColumn} id={cs.actContainer}>
          <img id={cs.actLogo} src={actLogo} alt="ACT Logo"></img>
          <img id={cs.actAcronym} src={actAcronym} alt="Advanced Combat Tactics"></img>
        </div>
      </div>
      <div className={cs.gjsGridRow} id={cs.connectContainer}>
        <div className={cs.gjsGridColumn} id={cs.connectItemsWrapper}>
          <div className={cs.gjsGridRow} id={cs.connectItemsContainer}>
            <div className={cs.gjsGridColumn} id={cs.leftBracketContainer}>
              <img className={cs.leftBracket} id={cs.leftBracket} src={bracketL} alt="left Connect Button Bracket"></img>
            </div>
            <div className={cs.gjsGridColumn} id={cs.innerButtonContainer}>
              <div className={cs.gjsGridRow} id={cs.upperBinaryWrapper}>
                <div className={cs.gjsGridColumn} id={cs.upperBinaryContainer}>

                  <div className={cs.marqueeUpper}>
                    <div className={cs.marqueeUpperContent}>
                      <div className={cs.textBlock}>01100001 01100100 01110110 01100001 01101110 01100011 01100101 01100100 00100000 01100011 01101111 01101101 01100010 01100001 01110100 00100000 01110100 01100001 01100011 01110100 01101001 01100011 01110011</div>
                    </div>
                    <div className={cs.marqueeUpperContent}>
                      <div className={cs.textBlock}>01100001 01100100 01110110 01100001 01101110 01100011 01100101 01100100 00100000 01100011 01101111 01101101 01100010 01100001 01110100 00100000 01110100 01100001 01100011 01110100 01101001 01100011 01110011</div>
                    </div>
                  </div>

                </div>
              </div>
              <div className={cs.gjsGridRow} id={cs.connectButtonWrapper}>
                <div className={cs.gjsGridColumn} id={cs.connectButtonContainer}>

                  <svg className={cs.connectBorder} width="730.786" height="165.89" viewBox="0 0 193.354 43.892" xmlns="http://www.w3.org/2000/svg">
                    <path id="borderPath" d="M.16.164V43.73h193.033V.161z" fill="transparent" />
                    <text textAnchor="middle">
                      <textPath className={cs.textPath} href="#borderPath">

                        NO MORE BOT PROXY. NO MORE WALLET LOCKS. NO SMURFS. NO CHEATERS. : &#41; CROWD FUNDED OPEN QUALIFIER COMPETITIONS. PLAYER SUPPORTED ANTI CHEAT MEASURES. SKINS. REAL GAMES BY REAL PEOPLE. START WITH FUN, END WITH SKILL. GLOBAL ACCESSIBILITY.
                        <animate attributeName="startOffset" from="50%" to="150%" begin="0s" dur="30s" repeatCount="indefinite" />
                      </textPath>
                      <textPath className={cs.textPath} href="#borderPath" >

                        NO MORE BOT PROXY. NO MORE WALLET LOCKS. NO SMURFS. NO CHEATERS. : &#41; CROWD FUNDED OPEN QUALIFIER COMPETITIONS. PLAYER SUPPORTED ANTI CHEAT MEASURES. SKINS. REAL GAMES BY REAL PEOPLE. START WITH FUN, END WITH SKILL. GLOBAL ACCESSIBILITY.
                        <animate attributeName="startOffset" from="-50%" to="50%" begin="0s" dur="30s" repeatCount="indefinite" />
                      </textPath>
                    </text>
                  </svg>

                  <Link className={cs.link} to="/cta">
                    <button className={cs.connectButton}
                      onMouseEnter={() => expandBracket()}
                      onMouseLeave={() => shrinkBracket()}
                      onClick={() => shrinkBracket()}
                    >CONNECT</button>
                  </Link>
                </div>
              </div>
              <div className={cs.gjsGridRow} id={cs.lowerBinaryWrapper}>
                <div className={cs.gjsGridColumn} id={cs.lowerBinaryContainer}>

                  <div className={cs.marqueeUpper}>

                    <div className={cs.marqueeLowerContent}>
                      <div className={cs.textBlock}>01100001 01100100 01110110 01100001 01101110 01100011 01100101 01100100 00100000 01100011 01101111 01101101 01100010 01100001 01110100 00100000 01110100 01100001 01100011 01110100 01101001 01100011 01110011</div>
                    </div>

                    <div className={cs.marqueeLowerContent}>
                      <div className={cs.textBlock}>01100001 01100100 01110110 01100001 01101110 01100011 01100101 01100100 00100000 01100011 01101111 01101101 01100010 01100001 01110100 00100000 01110100 01100001 01100011 01110100 01101001 01100011 01110011 </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className={cs.gjsGridColumn} id={cs.rightBracketContainer}>
              <img className={cs.rightBracket} id={cs.rightBracket} src={bracketL} alt="Right Connect Button Bracket"></img>
            </div>
          </div>
        </div>
      </div>
      <div className={cs.gjsGridRow} id={cs.linkContainer}>
        <div className={cs.gjsGridColumn} id={cs.linkRowColumn}>

          <a href="https://discord.gg/RzU6NFQn" target="_blank" rel="noopener noreferrer" className={cs.imageLink} id={cs.imageLinkContainer}>
            <img id={cs.discordLogo} src={discordLogo} alt="Discord Logo"></img>
          </a>

          <div id={cs.seperator}>|</div>

          <a href="https://twitter.com/act_nexus" target="_blank" rel="noopener noreferrer" className={cs.imageLink} id={cs.imageLinkContainer}>
            <img id={cs.xLogo} src={xLogo} alt="X Logo"></img>
          </a>

        </div>
      </div>

    </motion.div>

  );
};

export default ConnectPageComponent;