import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CallToActionPageComponent from './components/CtaPage/CtaPage';
import ConnectPageComponent from './components/ConnectPage/ConnectPage';
import bgVid from './assets/SVG/bgvideo.mp4'

function App() {
  return (
    <Router>
      <video src={bgVid} autoPlay="autoplay" loop="loop" muted playsInline onContextMenu={"return false"} preload="auto" />
      <div className="App">
        <Routes>
          <Route exact path='/' element={<ConnectPageComponent />} />
          <Route exact path='/cta' element={<CallToActionPageComponent />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

//hello

export default App;
